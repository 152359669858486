.workoutSection {
  min-height: 52px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.workoutSection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  width: 90%;
  word-break: break-word;
}
.iconHolder {
  display: flex;
  justify-content: space-between;
  width: 55px;
}
.iconHolder img {
  height: 24px;
  width: 24px;
}
.btnBox {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.btnBox button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
}
.btnBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.btnBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.btnBox p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.btnBox span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

.option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonSection {
  width: 348px;
  display: flex;
  justify-content: space-between;
}

/*===== add workout css =====*/

.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.headingAddMember p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.headingAddMember span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.addForm {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  background-color: #fff;
}
.addSection {
  padding: 20px 15px 10px 20px;
}

.addSection p {
  margin-bottom: 0px;
}

.formInput {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
}
.formInput label p {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.formInput label span {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.formInputVideo {
  margin-top: 20px;
}

.formInputVideo svg {
  font-size: 26px;
  margin-right: 5px;
}

.formInputVideo span {
  color: #394858;
  font-size: 15px;
  font-weight: 700;
}

.formInputVideo h4 {
  color: #ff4300;
  font-size: 14px;
  font-weight: 600;
}

.switchCase {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchCaseInfo {
  display: flex;
  flex-direction: column;
}
.switchCaseInfo h3 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.86px;
  color: #ff4300;
}
.switchCaseInfo p {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.12px;
  color: #000;
  margin: 0;
}

.switchCaseInfo span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.12px;
  color: #000;
  margin: 0;
}

.addWorkoutBtn {
  background-color: #ececec;
  padding: 15px 12px 15px 12px;
}

.bottomBtn {
  display: flex;
  justify-content: space-between;
}

.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.noRecordFound p {
  text-align: center;
  color: #000;
  font-size: 15px;
}

.noRecordFound span {
  color: #ff4300;
  font-size: 15px;
}
.showImage {
  display: flex;
  /* position: relative; */
}

.showImagePreview {
  position: relative;
}

.workoutIMg {
  height: 110px;
  width: 110px;
  border-radius: 4px;
  /* position: relative; */
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  /* background-color: #404040; */
}

.showimge {
  position: relative;
}

.crossImage img {
  /* position: absolute;
  left: 87px;
  cursor: pointer;
  top: 0px;
  background-color: #ffffff; */
  cursor: pointer;
  right: 215px;
  top: -4px;
  background-color: #ffffff;
}

.CrossImg {
  position: absolute;
  z-index: 1;
  left: 86px;
  background-color: #fff;
  border-radius: 4px;
  top: 0px;
}
.deleteButton {
  width: 94px;
  height: 36px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteButton p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  font-weight: 500;
}

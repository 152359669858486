.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 15px;
}

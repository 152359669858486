.dateSelection {
  min-width: 188px;
  height: 38px;
  border-radius: 4px;
  background-color: #f6f6f6;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}

.dateSelection img {
  height: 25px;
  width: 24px;
}

.dateSelection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1) !important;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 0px 10px;
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

.workoutHeader {
  height: 50px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #000000;
  background: #000000;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 10px;
}

.partName {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.partName p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 20px;
  color: #000000;
  margin: 0;
}

.workoutHeader h1 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  margin-left: 15px;
}

.workoutHeader span {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.dateTimeGroupBox {
  width: 100%;
  border-left: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  padding: 15px;
  background: #ffffff;
  margin-bottom: 15px;
  border-bottom: 1px solid #b5b5b5;
}

.dateTimeGroupBox p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14.5px;
  color: #394858;
}

.dateTimeGroupBox span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15.5px;
  color: #394858;
}

.selectDateAndTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timePickerBox {
  position: relative;
}

.timePickerBox svg {
  position: absolute;
  left: 5px;
  top: 7px;
  font-size: 24px;
  color: #000;
}

.timePicker {
  width: 132px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 40px;
}

.timePicker::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.datePickerBox {
  position: relative;
}

.datePicker {
  width: 188px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background-color: #f6f6f6;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.datePicker::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.datePickerBox svg {
  position: absolute;
  left: 5px;
  top: 8px;
}

.checkBoxArea input {
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  background-color: #eaeaea;
}

.checkBoxArea input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #979797;
  background-color: #eaeaea;
}

.checkBoxArea input:checked {
  background-color: #000000;
}

.checkBoxArea label {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  color: #394858;
  margin: 0;
}

.selectTraingBox p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0px 0px 10px 0px;
}

.selectTraingBox span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}

.checboxTraining {
  margin-left: 50px;
}

.checboxTraining input {
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  background-color: #eaeaea;
}

.checboxTraining input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #979797;
  background-color: #eaeaea;
}

.checboxTraining input:checked {
  background-color: #000000;
}

.checboxTraining label {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  color: #394858;
  margin: 0;
}

.saveBox {
  height: 58px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #ced4da;
  background: #ececec;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 10px;
  margin-top: 20px;
}

/* .saveBox button {
  width: 131px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 30%;
}
.saveBox button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
.saveBox button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
} */
.savedWorkoutBox {
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  background: #ffffff;
  border-left: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  padding: 15px;
  margin-bottom: 10px;
}

.savedWorkoutBoxManually {
  width: 100%;
  /* border-radius: 0px 0px 5px 5px; */
  background: #ffffff;
  border-left: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  padding: 15px 15px 0px 15px;
}

.savedWorkoutBox p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14.5px;
  color: #000000;
  margin: 0;
}

.savedWorkoutBox span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14.5px;
  color: #000000;
  margin: 0;
}

.selectWorkout {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.selectWorkout select {
  width: 416px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.selectWorkout select option p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.selectWorkout select option span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.addBtnBox {
  height: 38px;
  width: 38px;
  border-radius: 4px;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.iconHolder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectWorkout img {
  position: absolute;
  left: 370px;
  top: 46px;
}

.workoutNoteThat {
  margin-top: 8px;
}

.workoutNoteThat p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}

.workoutNoteThat span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}

.savedWorkoutBox button {
  width: 122px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.savedWorkoutBox button p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.savedWorkoutBox button span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.addManually p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14.5px;
  color: #000000;
  margin: 0px 0px 15px 0px;
}

.addManually span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14.5px;
  color: #000000;
  margin: 0;
}

.selectWorkoutManually {
  position: relative;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 15px;
}

.selectWorkoutManually select {
  width: 100%px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.selectWorkoutManually select option p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.selectWorkoutManually select option span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.selectWorkoutManually img {
  /* position: absolute;
  right: 15px;
  top: 15px; */
  position: absolute;
  right: 70px;
  top: 61px;
}

.videoLabel {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.videoIcon {
  width: 26px;
  height: 24px;
  border-radius: 2px;
  background: #3f3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoLabel h1 {
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 0px 0px 10px;
}

.videoLabel span {
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  /* margin: 0px 0px 0px 10px; */
}

.videoNote {
  margin-top: 15px;
}

.videoNote p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  /* margin: 0; */
}

.addLink {
  margin-top: 15px;
}

.addLink p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.addLink span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}

.addLink input {
  margin-top: 15px;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid rgba(205, 205, 205, 1);
  background-color: rgba(246, 246, 246, 1);
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 15px;
}

.addLink input::placeholder {
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
}

.addLink input:focus {
  box-shadow: none;
  outline: none;
}

.uploadImage button {
  width: 133px;
  height: 38px;
  border-radius: 3.5999999046325684px;
  opacity: 1;
  background-color: rgba(255, 67, 0, 1);
  border: 1px solid rgba(255, 67, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadImage {
  margin-top: 15px;
  margin-bottom: 15px;
}

.uploadImage button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.uploadImage button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.cancelDeleteSave {
  width: 100%;
  height: 58px;
  border-radius: 0px 0px 5px 5px;
  border-left: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  background: #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 10px;
}

.cancelSave {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cancelSave button {
  width: 94px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #000000;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #000000;
  margin-right: 10px;
}

.saveWorkout {
  width: 131px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveWorkout p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.saveWorkout span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.workoutDetail h1 {
  font-family: Manrope;
  font-weight: 600;
  font-size: 19px;
  color: #000000;
  margin: 0px 0px 10px 0px;
  word-break: break-word;
}

/* .workoutDetail p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: 0;
} */
.textEditorTextRoute {
  margin-bottom: 20px !important;
}

.textEditorTextRoute p {
  margin-bottom: 0px;
  margin-top: 0;
  font-size: 14px;
  font-family: "Manrope";
  line-height: 24px;
  word-break: break-all;
}

.textEditorText p {
  margin-bottom: 0px;
  margin-top: 0;
  font-size: 14px;
  font-family: "Manrope";
  line-height: 24px;
  word-break: break-all;
}

.selectWorkoutManually input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: #f6f6f6;
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  padding: 0px 15px;
}

.selectWorkoutManually input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
}

.selectWorkoutManually input:focus {
  outline: none;
  box-shadow: none;
}

.distanceInputBox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.km1 {
  width: 65px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: #f6f6f6;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  box-shadow: none;
  outline: none;
}

.distanceInputBox p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0px 10px;
}

.km2 {
  width: 39px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: #f6f6f6;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  box-shadow: none;
  outline: none;
}

.chooseKm {
  width: 135px;
  height: 35px;
  border-radius: 3.6px 0px 0px 3.6px;
  border: 1px solid #ff4300;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: 25px;
}

.chooseMile {
  width: 90px;
  height: 35px;
  border-radius: 0px 3.6px 3.6px 0px;
  border: 1px solid #ff4300;
  opacity: 1;
  font-family: "Manrope-Bold";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.getDetailBox {
  padding: 15px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
}

.getDetailBoxPtag {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 19px;
  font-weight: 600;
  margin: 0;
  word-break: break-all;
}

.distanceOfRoute {
  opacity: 1;
  color: rgba(0, 0, 0, 1) !important;
  font-family: Manrope;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 0;
}

.routeInfoDetail {
  font-family: Manrope;
  font-weight: 400 !important;
  font-size: 14.5px !important;
  color: #000000 !important;
  margin: 0px 0px 20px 0px !important;
}

.getDetailBox h1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.topic p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 700;
  line-height: 24px;
}

.topic span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 400;
  line-height: 24px;
}

.description {
  margin-top: 30px;
}

.description p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px 0px 10px 0px;
}

.routeInfo {
  display: flex;
  width: 220px;
  justify-content: space-between;
}

.linkBox {
  width: 98px;
  height: 98px;
  border-radius: 5.6px;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkBox p {
  opacity: 1;
  color: rgba(63, 63, 63, 1);
  font-family: Manrope;
  font-size: 16.799999237060547px;
  font-weight: 400;
  letter-spacing: 0.42px;
  margin: 0;
}

.linkBox h1 {
  opacity: 1;
  color: rgba(63, 63, 63, 1);
  font-family: Manrope;
  font-size: 16.799999237060547px;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin: 0;
}

.linkBoxIcon {
  width: 36.4px;
  height: 33.6px;
  border-radius: 2.4px;
  background: #3f3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 15px;
}

.headingAddMember p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.headingAddMember span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.dobInput input {
  width: 188px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  border-radius: 5px;
  /* padding: 0px 40px; */
  padding: 0px 32px;
}

.dobInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}

.dobInput label {
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #394858;
  margin-top: 9px;
}

.dobInput input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.disbleDatepicker {
  width: 132px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c41f;
  background: #f6f6f69c;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0000003d;
  padding-left: 40px;
}

.timedatePicker {
  width: 132px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 40px;
}

.timedatePicker::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.form-control:disabled {
  background-color: #f6f6f6 !important;
  opacity: 1;
}

.vedioShow {
  background-color: #e5e5e5;
  width: 98px;
  height: 98px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.vedioShow h3 {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.vedioShow svg {
  font-size: 26px;
  margin-right: 5px;
}

.vedioShow p {
  font-size: 16px;
  font-weight: 400;
  color: #3f3f3f;
  text-align: center;
  margin: 0px;
}

.vedioShow h1 {
  font-size: 16px;
  font-weight: 700;
  color: #3f3f3f;
  text-align: center;
}

.routeImage img {
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.detailImage img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 1px solid #c1c1c1;
  margin-right: 20px;
  margin-bottom: 10px;
}

.detailImage {
  margin-top: 20px;
  margin-bottom: 20px;
}

.noRouteData p {
  color: #000;
  text-align: center;
  padding: 10px 10px;
}

.editRoutes {
  width: 131px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editRoutes p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.editRoutes span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.editBtn {
  display: flex;
  justify-content: end;
  margin-bottom: 8px;
}

.showimagePreview img {
  height: 110px;
  width: 110px;
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: cover;
}

.showimge {
  position: relative;
}

.crossImage img {
  position: absolute;
  left: 87px;
  cursor: pointer;
  top: 0px;
  background-color: #ffffff;
}

.pushNotification h1 {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.pushNotification p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}

.notifyText label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
}
.notifyText input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;

  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyText input:focus {
  border: 1px solid #dee2e6;
  text-decoration: none !important;
  box-shadow: none !important;
}
.notifyText input ::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyButton {
  height: 54px;
  width: 301px;
  border-radius: 4px;
  background-color: #7b7a7a;
  border: 1px solid #7b7a7a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
}
.notifyButton p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color: #ffffff;
}
.notifyButton img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.notificationList {
  display: flex;
  margin-bottom: 5px;
}
.notificationList p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin: 0;
  width: 100%;
}
.notificationList span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}
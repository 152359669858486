.heading {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.47999998927116394px;
  text-align: left;
  color: #000000;
  margin: 0px 0px 20px 0px;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 15px;
}
.selectImgBox {
  width: 100%;
  height: 123px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 20px;
}
.selectImgBox p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.selectImgBox h1 {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.imgBox {
  height: 110px;
  width: 110px;
  border-radius: 4px;
  position: relative;
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  /* background-color: #404040; */
}
.imgBox.dragging {
  opacity: 0.5;
}
.previewImg {
  height: 110px;
  width: 110px;
  border-radius: 4px;
  object-fit: cover;
}
.crossImg {
  position: absolute;
  left: 86px;
  cursor: pointer;
  background-color: #ffffff;
}
.footer {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 20px;
}
.footer p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.footer span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.selectImgBox {
  margin-top: 20px;
}

.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.CrossImg {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  top: -5px;
  left: 89px;
  background: #ffffff;
}

.showImg{
  display: flex;
  flex-wrap: wrap;
  width: 537px;
}




.inputTextForAddGroup {
  margin-top: 15px;
}

.inputTextForAddGroup label p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #394858;
  margin: 0;
}
.inputTextForAddGroup label span {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #394858;
  margin: 0;
}
.inputTextForAddGroup input {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputTextForAddGroup input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.inputTextForAddGroup textarea {
  height: 117px;
  width: 100%;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputTextForAddGroup textarea::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}

.inputTextForBrandInfo {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
}
.inputTextForBrandInfo label p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.inputTextForBrandInfo label span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.inputTextForBrandInfo input {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 0px 10px;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.inputTextForBrandInfo input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.inputTextForBrandInfo input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectCountry {
  left: 57px;
  position: absolute;
  top: 37px;
  pointer-events: none;
  z-index: 99999;
}
.selectCountry2 {
  left: 57px;
  position: absolute;
  top: 37px;
  pointer-events: none;
  z-index: 99999;
}
.selectInput {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
  width: 278px;
}
.selectInput label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.selectInput select {
  width: 278px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.selectInput svg {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 30px;
}

.selectInput input {
  height: 38px;
  width: 278px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.inputBox {
  margin-left: 12px;
}

.inputBox input {
  width: 122px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 10px;
}

.inputBox input:focus {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  outline: none;
}

.bottomHighlight {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.errorMsg {
  width: 100% !important;
  font-size: 0.875em !important;
  color: red !important;
  margin: -10px 0px 0px 0px !important;
}
.errorMsgs p {
  width: 100% !important;
  font-size: 0.875em !important;
  color: red !important;
  margin: 0px 0px 0px 0px !important;
}

.passwordTabInput {
  position: relative;
  margin-bottom: 10px;
}
.passwordTabInput label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
}
.passwordTabInput input {
  width: 300px;
  height: 38px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;

  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.passwordTabInput input::placeholder {
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.passwordTabInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.passwordTabInput svg {
  position: absolute;
  top: 40px;
  left: 268px;
  font-size: 20px;
}

.signUpTabInput {
  position: relative;
  min-height: 80px;
}
.signUpTabInput label p {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.signUpTabInput label span {
  margin: 0;
  font-family: Manrope;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.signUpTabInput input {
  width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f5f5f5;
  padding-left: 38px;
}
.signUpTabInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #727272;
}
.signUpTabInput svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 41px;
  left: 8px;
  color: #727272;
}

.lockIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0px;
  /* bottom: 51px; */
  color: #727272;
  cursor: pointer;
  left: 318px;
}

.signUpTabInput2 {
  position: relative;
  min-height: 50px;
  margin-top: 5px;
}
.signUpTabInput2 label p {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.signUpTabInput2 label span {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.signUpTabInput2 input {
  width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f5f5f5;
  padding-left: 38px;
}
.signUpTabInput2 input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #727272;
}
.signUpTabInput2 svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 11px;
  left: 8px;
  color: #727272;
}

.DetailInput {
  margin: 0px 0px 15px 0px;
  position: relative;
}
.DetailInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
}
.DetailInput input {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}
.DetailInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.DetailInput select {
  font-family: Manrope;
  height: 38px;
  min-width: 310px;
  max-width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
}
.DetailInput p {
  position: absolute;
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  z-index: 1;
  margin: 0;
  top: 36px;
  left: 35px;
}
.DetailInput option {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .lockIcon {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0px;
    /* bottom: 51px; */
    color: #727272;
    cursor: pointer;
    left: 235px;
  }
  .selectInput {
    width: 100%;
  }
  .selectInput select {
    width: 100%;
  }
  .selectInput svg {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 30px;
  }
  .selectInput input {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 364px) {
  .selectCountry {
    left: 57px;
    position: absolute;
    top: 61px;
  }
}
@media screen and (min-width: 320px) and (max-width: 424px) {
  .selectCountry2 {
    left: 57px;
    position: absolute;
    top: 61px;
  }
}

.inputTextForTraining {
  padding: 0px 15px 20px 20px;
}

.inputTextForTraining input {
  height: 38px;
  width: 100%;
  max-width: 344px;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputTextForTraining input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.inputTextForTraining input:focus {
  outline: none;
  border: 1px solid #c1c1c1;
  box-shadow: none;
  background: #f6f6f6;
}

/* .inputTextForAddWork{
  padding: 0px 15px 20px 20px
} */

.inputTextForAddWork p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.inputTextForAddWork span {
  color: #000;
  font-size: 14px;
  font-weight: 600 !important;
}

.inputTextForAddWork input {
  height: 38px;
  width: 100%;
  max-width: 466px;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  color: #000;
}
.inputTextForAddWork input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.inputTextForAddWork input:focus {
  outline: none;
  border: 1px solid #c1c1c1;
  box-shadow: none;
  background: #f6f6f6;
}

.traningCheck label {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
}
.customDropdown {
  position: absolute !important;
  top: -200px; 
  left: 0;
  z-index: 1000; 
}


.customDropdownWhatsApp {
  position: absolute !important;
  top: auto !important;
  bottom: -209px !important;
  left: 0;
  z-index: 1000; 
}


.inputTextForAddLogbook {
  margin-top: -12px;
}

.inputTextForAddLogbook label p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #394858;
  margin: 0;
}
.inputTextForAddLogbook label span {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #394858;
  margin: 0;
}
.inputTextForAddLogbook input {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputTextForAddLogbook input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.inputTextForAddLogbook textarea {
  height: 117px;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c4c4c4;
}
.inputTextForAddLogbook textarea::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}

.inputTextForAddLogbook textarea:focus{
  outline: none;
  border: 1px solid #c4c4c4;
  box-shadow: none;
}
.modalHead p {
  font-size: 20px;
  font-weight: 400;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}
.modalHead span {
  font-size: 20px;
  font-weight: 600;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}
.modalBody p {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
}

.btnSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.close {
  width: 90px;
  height: 34px;
  border-radius: 3.6px;
  background: #ffffff;
  border: 1px solid #000000;

  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.delete {
  height: 34px;
  border-radius: 3.6px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
  padding: 0px 15px;
  margin-left: 10px;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

/*-------- Add media query for manage responsive --------*/
@media screen and (max-width: 575px) {
  .close {
    width: 115px;
    height: 40px;
    font-size: 12px;
    margin-right: 5px;
  }
  .delete {
    width: 95px;
    height: 40px;
    font-size: 12px;
    margin-right: 5px;
  }
}

/* SIDEBAR STYLE============= */

.NavBody .navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: black !important;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

nav.NavBody {
  padding: 0px;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: 0px;
}

.offcanvas-body {
  background: #000000;
  flex-grow: 1;
  padding: 0px;
  overflow-y: auto;
  width: 260px;
  scrollbar-width: none;
}

.Sidebar_Navlink__wrxDM.active svg {
  color: #000000;
}

.Sidebar_Navlink__wrxDM.active {
  background-color: #ffffff;
  text-decoration: none;
  font-family: Manrope;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #000000;

  width: 190px;
  height: 46px;
  border: 1.2px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
  width: 240px;
}

.offcanvas-header .btn-close {
  display: none;
  color: white;
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y))
    calc(-0.5 * var(--bs-offcanvas-padding-x))
    calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

@media only screen and (max-width: 991px) {
  .MainBody {
    display: block;
  }

  .NavBody .navbar-toggler {
    position: absolute;
    margin-top: 3px;
    padding: var(--bs-navbar-toggler-padding-y)
      var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: black !important;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
    left: -28px;
  }
}

/* ============= */

.errorMsg {
  color: red;
}

.errorMsgedit {
  color: red !important;
  font-size: 15px !important;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.validateError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.validateErrorResult {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
  position: absolute;
  bottom: 0;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: auto;
}

.remeberChek {
  display: flex;
  justify-content: center;
}

.remeberChek .form-check label {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.remeberChek .form-check-input {
  background-color: #424242;
  border: 2px solid #ffffff;
}

.remeberChek .form-check-input:checked {
  background-color: #424242;
  border-color: #fff;
}

.remeberChek .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

.remeberChek123 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.remeberChek123 p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #ffffff;
  cursor: pointer;
}

.remeberChek123 .form-check label {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.remeberChek123 .form-check-input {
  background-color: #424242;
  border: 2px solid #ffffff;
}

.remeberChek123 .form-check-input:checked {
  background-color: #424242;
  border-color: #fff;
}

.remeberChek123 .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.acceptTerms {
  display: flex;
  margin: 0px 0px 10px 0px;
}

.acceptTerms .form-check label {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  line-height: 17.76px;
  text-align: left;
  color: #000000;
}

.acceptTerms .form-check-input {
  width: 18px;
  height: 18px;
  border: 1px solid #c4c4c4;
}

.acceptTerms .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.acceptTerms .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/* ================= */

.grpCheck {
  display: flex;
}

.grpCheck .form-check label {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}

.grpCheck .form-check-input {
  width: 18px;
  height: 18px;
  border: 2px solid #828282;
}

.grpCheck .form-check-input:checked {
  width: 18px;
  height: 18px;
  background-color: #ff4300 !important;
  border: 2px solid #ff4300 !important;
}

.form-check-input:checked {
  background-color: #ff4300 !important;
  border-color: #828282 !important;
}

.grpCheck .form-check-input:focus {
  border-color: #828282;
  outline: none;
  box-shadow: none;
}

/* TABS */
.nav-tabs .nav-link {
  color: #000000;
  background-color: #ffffff;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  width: 84px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 10px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #000000;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  width: 84px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 10px;
}

.tabs {
  margin-bottom: 1rem !important;
  border-bottom: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  /* margin: 15px 0px; */
  padding: 15px 0px;
}

/* CALENDER  */
.dateModal {
  width: 100%;
  height: 100%;
}

.dateModal .modal-dialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 395px;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  padding: 18px;
  border: 1px solid #e0e0e0;
}

.react-calendar {
  width: 358px !important;
  max-width: 100% !important;
  background: white !important;
  border: 0px solid #a0a096 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
}

.react-calendar__navigation button {
  font-family: Poppins;
  font-weight: 600;
  font-size: 19px;
  color: #2c3135;
}

.react-calendar__navigation button {
  min-width: 34px;
  background: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  /* height: 38px; */
  width: 34px;
  background-color: #ff4300;
  border-radius: 50%;
  border: 1px solid #ff4300;
  /* margin-top: 5px; */
  color: #ff4300;
  font-size: 0px;
  background-image: url(../../Assests/Images/lesssthanActive.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  /* height: 38px; */
  width: 34px;
  background-color: #ff4300;
  border-radius: 50%;
  border: 1px solid #ff4300;
  /* margin-top: 5px; */
  color: #ff4300;
  font-size: 0px;
  background-image: url(../../Assests/Images/greaterthanActive.png);
  background-repeat: no-repeat;
  background-position: center;
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration-line: none !important;
  padding: 0.5em;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  color: #ff4300;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 42px !important;
  width: 49px !important;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  background-color: #eeeeee;
  color: #2c3135;
  flex: none !important;
  margin: 1px;
}

.react-calendar__tile:disabled {
  background-color: #ffffff !important;
  color: #2c3135 !important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #ff4300;
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  width: 49px !important;
  height: 42px !important;
  background: #eeeeee;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #6f7377;
  flex: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:enabled:hover {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button:enabled:hover {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:enabled:focus {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button:enabled:focus {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background: #ff4300;
  color: #ffffff;
}

/* Selectable property */
/* button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: #ff4300 !important;
} */
/* -------------- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 991px) {
  .dateModal .modal-dialog .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 315px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    padding: 18px;
    border: 1px solid #e0e0e0;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    height: 42px !important;
    width: 38px !important;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #2c3135;
    flex: none !important;
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    height: 42px !important;
    width: 38px !important;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #2c3135;
    flex: none !important;
    margin: 1px;
  }

  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    width: 38px !important;
    height: 42px !important;
    background: #eeeeee;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #6f7377;
    flex: none !important;
  }
}

/* ==============TEXT EDITOR */
.ql-toolbar.ql-snow {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  font-family: "Manrope", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  background: linear-gradient(180deg, #eee 0%, #d8d8d8 100%);
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

.ql-toolbar.ql-snow span button {
  border-radius: 2px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
}

.ql-editor.ql-blank {
  /* height: 110px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 38px;
  background-color: #ffffff;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.quill .ql-container.ql-snow .ql-editor p {
  font-size: 15px !important;
  font-family: Manrope;
  font-weight: 400;
  color: #6c757d;
}

.quill .ql-container.ql-snow .ql-editor ol li {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.quill .ql-container.ql-snow .ql-editor ol li strong {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.quill .ql-container.ql-snow .ql-editor.ql-blank::before {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
  color: #6c757d !important;
  font-style: normal !important;
}

.quill .ql-container.ql-snow .ql-editor {
  /* height: 110px !important; */
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #ffffff;
}

/* .brandTextEditor .ql-editor {
  resize: vertical;
  overflow: auto;
} */
.ql-editor {
  overflow-y: scroll;
  resize: vertical;
}

/* ============= */
.quill.WorkoutTextEditor .ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  /* padding: 8px; */
  height: 30px;
  display: flex;
  align-items: center;
}

.quill.WorkoutTextEditor .ql-toolbar.ql-snow span button {
  border-radius: 2px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
  margin-top: 0;
}

.quill.WorkoutTextEditor .ql-container.ql-snow .ql-editor {
  height: 159px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #ffffff;
}

.quill.WorkoutTextEditor .ql-container.ql-snow .ql-editor p {
  font-size: 15px !important;
  font-family: Manrope;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
}

.quill.WorkoutTextEditor .ql-container.ql-snow .ql-editor ol li {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.quill.WorkoutTextEditor .ql-container.ql-snow .ql-editor ol li strong {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.quill.WorkoutTextEditor .ql-container.ql-snow .ql-editor.ql-blank::before {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
  color: #000000 !important;
  font-style: normal !important;
}

/* ========================= */

.quill.brandTextEditor .ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  /* padding: 8px; */
  height: 30px;
  display: flex;
  align-items: center;
}

.quill.brandTextEditor .ql-toolbar.ql-snow span button {
  border-radius: 2px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
  margin-top: 0;
}

.quill.brandTextEditor .ql-container.ql-snow .ql-editor {
  min-height: 180px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #ffffff;
}

.quill.brandTextEditor .ql-container.ql-snow .ql-editor p {
  font-size: 15px !important;
  font-family: Manrope;
  font-weight: 400;
  color: #000000;
  /* color: #6c757d; */
  line-height: 24px;
  color: #000;
}

.quill.brandTextEditor .ql-container.ql-snow .ql-editor ol li {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.quill.brandTextEditor .ql-container.ql-snow .ql-editor ol li strong {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.quill.brandTextEditor .ql-container.ql-snow .ql-editor.ql-blank::before {
  font-size: 15px !important;
  font-family: Manrope !important;
  font-weight: 400 !important;
  color: #6c757d !important;
  font-style: normal !important;
}

/* PHONE INPUT */
.react-tel-input.PhoneInputArea .selected-flag .arrow {
  display: none;
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.react-tel-input.PhoneInputArea input {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
  padding-left: 130px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  width: 350px;
  max-width: 100%;
  outline: none;
}

.react-tel-input.PhoneInputArea .flag-dropdown .selected-flag {
  width: 125px;
  background-color: #e9e9e9;
  z-index: 9;
}

.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #6c757d !important;
  font-family: inherit;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.react-tel-input.signUpPhoneInput .selected-flag .arrow {
  display: none;
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.react-tel-input.signUpPhoneInput input {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
  padding-left: 85px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  outline: none;
}

.react-tel-input.signUpPhoneInput .flag-dropdown .selected-flag {
  width: 71px;
  background-color: #e9e9e9;
  z-index: 0;
}

.react-tel-input.brandPhoneInput .selected-flag .arrow {
  display: none;
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.react-tel-input.brandPhoneInput input {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  color: #6c757d;
  padding-left: 105px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  outline: none;
}

.react-tel-input.brandPhoneInput .flag-dropdown .selected-flag {
  width: 91px;
  background-color: #e9e9e9;
  z-index: 0;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #e9e9e9 !important;
  border-radius: 3px 0 0 0;
}

/* INTERNATIONAL PHONE INPUT */
.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  width: 100%;
  height: 38px !important;
  box-sizing: border-box;
  padding: 0px 12px 0px 45px !important;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #6c757d !important;
  font-family: Manrope !important;
  font-size: 15px;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: #e9e9e9;
  width: 70px !important;
  height: 38px !important;
}

.react-international-phone-country-selector-button__dropdown-arrow {
  display: none;
  border-top: var(--react-international-phone-country-selector-arrow-size, 4px)
    solid var(--react-international-phone-country-selector-arrow-color, #777);
  border-right: var(
      --react-international-phone-country-selector-arrow-size,
      4px
    )
    solid transparent;
  border-left: var(--react-international-phone-country-selector-arrow-size, 4px)
    solid transparent;
  margin-right: 4px;
  transition: all 0.1s ease-out;
}

:root {
  --react-international-phone-flag-width: 32px;
  --react-international-phone-flag-height: 32px;
}

/* REACT CROPPER */
.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 268px;
  width: 498px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .reactEasyCrop_Container {
    width: 302px;
    height: 268px;
  }
}

svg.react-datepicker__calendar-icon.undefined.false {
  display: none;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  display: none;
}

.react-datepicker__view-calendar-icon input {
  padding: 0px 0px 0px 35px !important;
}

.otpStyiling {
  width: 85px !important;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  font-family: Manrope;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  box-shadow: none;
  outline: none;
  margin-right: 6px;
}

.otpErrorMsg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .otpStyiling {
    width: 65px !important;
  }
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-color: #ff4300 !important;
  border-color: #ff4300 !important;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
  width: 50px;
  height: 25px;
}

.form-switch .form-check-input {
  width: 50px;
  height: 25px;
}

.selectCheckBox {
  margin-right: 40px;
}

.selectCheckBox .form-check-input:checked {
  background-color: #ff4300;
  border-color: #ff4300;
}

.selectCheckBox .form-check-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectCheckBox .form-check-label {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
}

.phycicalChek .form-check label {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}

.phycicalChek .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1 !important;
}

.phycicalChek .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.phycicalChek .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

.selectDistanceCheckbox .form-check-input:checked {
  background-color: #ff4300;
  border-color: #ff4300;
}

.selectDistanceCheckbox .form-check-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectDistanceCheckbox .form-check-label {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
  border-radius: 4px !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 15px 0px 0px 10px !important;
  box-sizing: content-box;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: #57575b !important;
  color: #ffffff !important;
}

.react-datepicker__triangle {
  position: absolute;
  pointer-events: none;
  left: 69px;
  transform: translateY(-1px);
  left: 66px;
  top: 244px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  left: 62px !important;
  top: -15px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  left: 62px !important;
  top: 100% !important;
}

.lg-react-element.custom-wrapper-class {
  width: 510px;
  display: flex;
  flex-wrap: wrap;
  /* position: relative; */
}

.lg-react-element.custom-wrapper-class > * {
  flex: 1 1 23%;
  margin: 5px;
  max-width: calc(25% - 10px);
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .lg-react-element.custom-wrapper-class {
    width: 100%;
  }
}

/* radio button css in notification unit select*/
.switch-field {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.switch-field input {
  /* position: absolute !important; */
  /* clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; */
  display: none;
}

.switch-field .form-check input[type="radio"] {
  display: none;
}

.switch-field label {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px;
  border: 1px solid #ff4300 !important;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  font-weight: 700;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #ff4300 !important;
  box-shadow: none;
  border: 1px solid #ff4300 !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.switch-field .form-check {
  padding: 0px !important;
}

.switch-field .form-check:first-child label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.switch-field .form-check:last-child label {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-check-label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (min-width: 411px) and (max-width: 465px) {
  .switch-field label {
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 410px) {
  .switch-field label {
    margin-top: 10px;
  }
}

.form-control:disabled {
  background-color: #f6f6f6 !important;
  opacity: 1;
}

/* .reactEasyCrop_Contain {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 128px !important;
  bottom: 0;
  left: 31% !important;
  right: 0;
} */
.croperModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vh !important;
  height: 70vh !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.croperModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}

.form-check-input[type="checkbox"] {
  border: 1px solid #979797;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .croperModal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }
}

.socialButton button {
  border: none !important;
  background-color: #f6f6f6 !important;
}

/* .advanced-cropper-background-image {
  background-color: #404040;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  transform-origin: center;
  pointer-events: none;
  max-width: none !important;
} */

.PhoneInputArea .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 110px !important;
  height: 100%;
  padding: 0 0 0 8px;
  background: #000000 !important;
}

.open-upwards .form-select {
  position: relative;
}

.open-upwards .form-select select {
  bottom: 100%;
  top: auto;
}

/* new dropdown css */
select {
  -webkit-appearance: none;
  max-height: 150px;
  overflow-y: auto;
}

select::-webkit-scrollbar {
  width: 12px;
}

select::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

select::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 15px;
}
.subHeading h1 {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.subHeading p {
  opacity: 1;
  color: rgba(130, 128, 128, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.inputFieldQues label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
}
.inputFieldQues input {
  height: 38px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
}
.inputFieldQues input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #cdcdcd;
}
.inputFieldQues input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.ansHeading {
  margin: 20px 0px 10px 0px;
}
.ansHeading p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.ansHeading span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.inputFieldAns {
  display: flex;
  justify-content: space-between;
}

.inputFieldAns input {
  height: 38px;
  width: 80%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
}
.inputFieldAns input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #cdcdcd;
}
.inputFieldAns input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.listBox {
  height: 38px;
  width: 38px;
  border-radius: 4px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 10px;
}
.crossImg {
  height: 38px;
  width: 38px;
  cursor: pointer;
}
.votes {
  margin: 5px 0px;
  cursor: pointer;
}
.votes p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.votes span {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
.activeEventSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 15px;
}

.activeEventSection p {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.switchButton {
  font-size: 25px;
}
.submitSection {
  height: 60px;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  background: #ffffff;
  border: 1px solid #515151;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 20px;
}
.voterBox {
  height: 46px;
  width: 390px;
  padding: 0px 15px 0px 0px;
  /* margin: 20px 0px; */
  border-radius: 3.6px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.voterDetail {
  display: flex;
  align-items: center;
}
.voterBox img {
  height: 46px;
  width: 46px;
  object-fit: cover;
  border-radius: 50%;
}
.voterBox p {
  opacity: 1;
  color: rgba(34, 51, 80, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 300;
  margin: 0px 0px 0px 10px;
}
.voterBox span {
  opacity: 1;
  color: rgba(34, 51, 80, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}
.voterBoxCross {
  height: 24px !important;
  width: 24px !important;
  border-radius: 0 !important;
}
.VoterListBox {
  max-height: 195px;
  overflow-y: auto;
  width: 80%;
  gap: 21px;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.VoterListBox::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.VoterListBox::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.VoterListBox::-webkit-scrollbar-thumb {
  background: black;
}

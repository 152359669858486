.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 15px;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

.noActiveGrp P {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 46px; */
  margin: 0px 0px 15px 0px;
}
.noActiveGrp span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
}

.bottomSection {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  margin-top: 15px;
}
.bottomSection button {
  height: 34px;
  width: 110px;
  border-radius: 4px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottomSection button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
.bottomSection button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}
.result p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}
.result span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.listHead p {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.listHead span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.detailBox {
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameSection {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
}
.serial {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: "Manrope-Bold";
  font-size: 16px;
  font-weight: 700;
  margin: 0px 10px 0px 0px;
}
.name {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  word-break: break-all;
}
.otherDetail {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.age {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}
.age span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}
.kmName {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.optionImgSection {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.optionImg {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.noActiveGrp P {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 46px; */
  margin: 0px 0px 15px 0px;
}
.noActiveGrp span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
}

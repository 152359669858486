.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 15px;
}
.keyAndValue {
  margin-bottom: 10px;
}
.keyAndValue p {
  opacity: 1;
  color: rgba(64, 64, 64, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 600;
  margin: 0;
}
.keyAndValue h1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}
.detailKeys {
  margin-top: 10px;
  margin-bottom: 10px;
}
.detailKeys p {
  opacity: 1;
  color: rgba(64, 64, 64, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 600;
  margin: 0;
}
.detailKeys h1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}
.detailKeys img {
  margin-top: 10px;
  width: 100%;
  height: 374px;
}
.buttonSection {
  margin-bottom: 10px;
}
.buttonSection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 600;
  margin: 0;
}
.resultDistances p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}
.resultDistances span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

.pushNotification h1 {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.pushNotification p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.notifyText label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
}
.notifyText input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;

  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyText input:focus {
  border: none;
  text-decoration: none !important;
  box-shadow: none !important;
}
.notifyText input ::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyButton {
  height: 54px;
  width: 301px;
  border-radius: 4px;
  background-color: #7b7a7a;
  border: 1px solid #7b7a7a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
}
.notifyButton p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color: #ffffff;
}
.notifyButton img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.sentDetail p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.sentDetail span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.activeEventSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 15px;
}
.activeEventSection p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}
.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}
.switchButton {
  font-size: 25px;
}
.eventBtn {
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
  margin-top: 20px;
  border-radius: 4px;
  height: 60px;
  padding: 13px 10px;
}

.notificationList {
  display: flex;
}
.notificationList p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin: 0;
}
.notificationList span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}
.notificationListPtag {
  font-family: Manrope;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000000;
  margin: 0px 0px 0px 5px !important;
  word-break: break-all !important;
  max-width: 330px !important;
}
.textEditorText p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin: 0;
}

.MainDiv {
  height: 100vh;
  width: 100%;
  background-color: #3f3f3f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MainDiv img {
  width: 50px;
  height: 50px;
}
.MainDiv p {
  color: #ef6f22;
  margin: 0;
  font-family: Manrope;
  font-size: 25px;
  font-weight: 700;
}

.AppRoot {
  display: flex;
  height: 100vh;
  /* border: 2px solid; */
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f5f4f4;
}

.MainPage {
  flex-grow: 0;
  /* flex-grow: 1; */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 46px 0px 25px 39px;
  margin-right: 2px;
  /* width: 535px; */
  width: 80%;
}

.MainPage::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.MainPage::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.MainPage::-webkit-scrollbar-thumb {
  background: none;
}

.mainPageChild {
  width: 500px;
}

@media only screen and (max-width: 991px) {
  .AppRoot {
    display: block;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: #f5f4f4;
  }
  .MainPage {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100%;
    padding: 0px 5px 25px 5px;
    margin-right: 2px;
    width: 100%;
  }
  .mainPageChild {
    width: 100%;
  }
}

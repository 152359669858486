.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px 15px 0px;
}

@media only screen and (max-width: 991px) {
  .previousOrNext {
    height: 45px;
    width: 72px;
    border: 1px solid #ff4300;
    border-radius: 4px;
    background-color: #ff4300;
  }
}

/*--------- Add event page css-------- */
.clublabelText {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.clublabelText span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  color: #000000;
}

.eventTitle {
  border-bottom: 1px solid #c1c1c1;
  padding: 0px 0px 15px 0px;
}

.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 38px;
  font-size: 15px;
  width: 100%;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #000000;
  width: 100%;
  /* height: 38px; */
  border-radius: 4px;
}

.routeContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.routeContent button {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ff4300 !important;
  border: none;
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.labelText p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.labelText span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.infoLabel {
  border-bottom: 1px solid #c1c1c1;
  padding: 12px 0px 20px 0px;
}

.eventImage {
  padding: 18px 0px 25px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.eventImage h4 {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.labelText {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.eventImage span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  color: #000000;
}

.eventImage p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 14px 0px;
}

.routeHeading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.uploadLabel {
  text-align: center;
  padding: 10px 30px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.imgUpload {
  border: 1px solid #c1c1c1;
  margin-top: 15px;
  position: relative;
  width: 100%;
  max-width: 270px;
  object-fit: cover;
  padding: 30px;
}

.imgUpload img {
  width: 100%;
  max-width: 270px;
  height: 110px;
  object-fit: cover;
}

.closeIcon {
  font-size: 25px;
  position: absolute;
  right: -3px;
  top: -6px;
  color: #fff;
  background-color: #ff4300;
  font-weight: 600;
  border-radius: 5px;
}

.adBtn {
  padding: 13px 0px 20px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.adBtn p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.activeEventSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 15px;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.activeEventSection p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}

.activeEventSection h3 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 0;
}

.eventBtn {
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
  margin-top: 20px;
  border-radius: 4px;
  height: 60px;
  padding: 13px 10px;
}

.cancel button {
  /* width: 81px; */
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #fff;
}

.delete button {
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: transparent;
  color: #000000;
}

.delete button:hover {
  color: #000000;
  background-color: #ccc;
  border-color: #ccc;
}

.disabledButton {
  opacity: 30% !important;
  border: 1px solid #000 !important;
  background-color: transparent !important;
  color: #000000 !important;
}

.save button {
  /* width: 103px; */
  height: 34px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
  color: #fff;
}

.save button span {
  font-weight: 700 !important;
  margin: 0 !important;
}

.switchButton {
  font-size: 25px;
}

.previewMembershipImgBox {
  max-width: 400px;
  max-height: 677px;
  position: relative;
  margin-top: 20px;
}

.previewImg {
  height: 677px;
  object-fit: contain;
  width: 400px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}

.removeImg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}

.dateSelection {
  width: 220px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}

.dateSelection img {
  width: 24px;
  height: 25px;
}

.dateSelection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0px 0px 10px;
}

.pushNotification h1 {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.pushNotification p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}

.notifyText label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
}

.notifyText input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;

  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.notifyText input:focus {
  border: none;
  text-decoration: none !important;
  box-shadow: none !important;
}

.notifyText input ::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.notifyButton {
  height: 54px;
  width: 301px;
  border-radius: 4px;
  background-color: #7b7a7a;
  border: 1px solid #7b7a7a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
}

.notifyButton p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color: #ffffff;
}

.notifyButton img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.distanceSection {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-bottom: 1px solid #c1c1c1;
  margin-top: 15px;
  padding-bottom: 8px;
}

.distanceData {
  display: flex;
  align-items: center;
  height: 50px;
}

.errorMessage {
  width: 100%;
  margin: 0.25rem 0;
  font-size: 0.875em;
  color: red;
}

.distanceSection h3 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 0;
}

.Distance h6 {
  font-family: Manrope;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin: 0;
}

.timeInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeInput input {
  width: 50px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  /* padding: 0px 14px; */
  text-align: center;
}

.dot {
  display: inline-block;
  margin: 0;
  font-size: 50px !important;
  vertical-align: middle;
  color: #000000 !important;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.Distance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  gap: 6px;
}

.routeDistance {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.routeDistance h4 {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.routeDistance p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 14px 0px;
}

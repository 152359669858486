.pageHead {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.32px;
  letter-spacing: 0.4571428596973419px;
  color: #000000;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttonSection {
  /* width: 260px; */
  display: flex;
  justify-content: space-between;
}
.detailHeading {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.detailHeading img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.detailHeading p {
  font-size: 15px;
  font-weight: 600;
  font-family: Manrope;
  color: #ffffff;
  line-height: 21px;
  margin: 0px 0px 0px 10px;
}
.detailSubHeading {
  margin-bottom: 20px;
}
.detailSubHeading p {
  font-size: 22px;
  font-weight: 400;
  font-family: Manrope;
  color: #000000;
  line-height: 30px;
  margin: 0;
}
.detailSubHeading span {
  font-size: 22px;
  font-weight: 700;
  font-family: Manrope;
  color: #000000;
  line-height: 30px;
  margin: 0;
}
.info {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.info p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  color: #394858;
  line-height: 18px;
  margin: 0;
}
.info h1 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin: 0;
}
.profilePic img {
  width: 150px;
  height: 150px;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
  object-fit: contain;
}
.timeHead p {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  line-height: 19px;
  margin: 0;
}
.timeHead span {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 300;
  color: #000000;
  line-height: 19px;
}
.time p {
  font-family: Manrope;
  font-size: 25px;
  font-weight: 300;
  line-height: 38px;
  color: #000000;
  margin: 0;
}

.editSection {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 15px;
}

.editSection button {
  height: 34px;
  width: 110px;
  border-radius: 4px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.personalBestBox {
  width: 320px;
  max-width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}
.personalBestBox p {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}
.personalBestBox span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  margin: 0;
}

.heading {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.47999998927116394px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonSection {
  width: 175px;
  display: flex;
  justify-content: space-between;
}
.members {
  height: 35px;
  background: #000000;
  border-radius: 20px;
  border: 1px solid #000000;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
  padding: 0px 20px;
}
.invites {
  height: 35px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
  padding: 0px 20px;
}
.search {
  width: 140px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 10px;
  margin-top: 0px;
}
.search:focus {
  box-shadow: none;
  outline: none;
}

.addGrp {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .option {
    flex-direction: column;
  }
  .buttonSection {
    width: 100%;
  }
  .search {
    width: 100%;
    margin-top: 10px;
  }
}

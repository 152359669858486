.formInput {
  height: 85px;
  width: 100%;
}
.formInput label p {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.formInput label span {
  margin: 0;
  font-family: Manrope;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}

.otpInput {
  width: 100%;
  border: 1px solid black;
}
.goBackButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.goBackButton button {
  width: 150px;
  height: 41px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}
.goBackButton button svg {
  font-size: 52px;
  color: #ff4300;
}
.goBackButton button p {
  font-size: 20px;
  font-family: Manrope;
  font-weight: 600;
  color: #ff4300;
  margin: 0;
}

.formSection {
  display: flex;
  flex-direction: column;
  /* height: 815px; */
  /* justify-content: center; */
  align-items: center;
}

.bgSection {
  background-color: #424242;
  height: 100vh;
  /* padding: 20px 20px; */
  justify-content: center;
  display: flex;
  /* align-items: center; */
  overflow-x: hidden;
  overflow-y: auto;
}

.logoImg img {
  width: 100%;
  max-width: 390px;
  margin-top: 110px;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  /* margin-bottom: 25px; */
  height: 25px;
}
.heading p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1.7391303777694702px;
  text-align: center;
  color: #ffffff;
}
.heading span {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 1.7391303777694702px;
  text-align: center;
  color: #ffffff;
}
.loginForm {
  width: 360px;
}
.loginForm h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/*login  button css */
.loginBtn {
  margin-top: 19px;
}
.loginBtn button {
  width: 100%;
  height: 41px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBtn button:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important; /* Custom hover color */
}
.loginBtn button p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #ffffff;
  margin: 0;
}
.loginBtn button span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}
.signUpWith {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.signUpWith p {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.49px;
  text-align: center;
  color: #ffffff;
}
.signInOption {
  width: 100%;
  display: flex;
  justify-content: center;
}
.option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 48px;
  background: #f6f6f6;
  border-radius: 10px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.alreadySignin {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.alreadySignin p {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.alreadySignin span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}
/* @media screen and (max-width: 340px) {
    .lockIcon svg {
      left: 270px;
    }
  }
  
  @media screen and (min-width: 341px) and (max-width: 359px) {
    .lockIcon svg {
      left: 290px;
    }
  } */

.loginInput {
  position: relative;
  margin-bottom: 15px;
}
.loginInput label p {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.loginInput input {
  width: 100%;
  height: 48px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f5f5f5;
  padding-left: 38px;
}
.loginInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #727272;
}
.loginInput svg {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 12px;
  left: 8px;
  color: #727272;
}

.loginInput12 {
  position: relative;
  height: 100px;
  margin-top: 5px;
}
.loginInput12 label p {
  margin: 0;
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}
.loginInput12 input {
  width: 100%;
  height: 48px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f5f5f5;
  padding-left: 50px;
}
.loginInput12 input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #727272;
}
.loginInput12 svg {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 11px;
  left: 8px;
  color: #727272;
}
.lockIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0px;
  /* bottom: 51px; */
  color: #727272;
  cursor: pointer;
  left: 318px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 81px;
    height: 45px;
    background: #f6f6f6;
    border-radius: 10px;
  }
  .loginForm {
    width: 280px;
  }
  .lockIcon {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0px;
    /* bottom: 51px; */
    color: #727272;
    cursor: pointer;
    left: 235px;
  }
  .logoImg img {
    width: 100%;
    max-width: 300px;
    margin-top: 110px;
  }
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px 15px 0px;
}

@media only screen and (max-width: 991px) {
  .previousOrNext {
    height: 45px;
    width: 72px;
    border: 1px solid #ff4300;
    border-radius: 4px;
    background-color: #ff4300;
  }
}

/*--------- Add event page css-------- */

.addeventBox {
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.newEventBox {
  border: 1px solid #ff4300;
  background-color: #ff4300;
  padding: 0px 15px 0px 25px;
  border-radius: 5px;
  height: 58px;
  display: flex;
  align-items: center;
}

.newEventBox p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 0px;
}

.newEventBox span {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.eventTitle {
  border-bottom: 1px solid #c1c1c1;
  padding: 15px 0px 15px 0px;
}

.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 38px;
  font-size: 15px;
  width: 100%;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #727272;
  width: 100%;
  /* height: 38px; */
  border-radius: 4px;
}

.inputIcon label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
}

.labelText p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.labelText span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}
.checkDisplay {
  display: flex;
  padding: 15px 0px 15px 0px;
}
.checkDisplay2 {
  display: flex;
}
.showDate {
  display: flex;
  justify-content: space-between;
}

.googleMap .dragLocation {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.selectDate {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 20px;
}

.selectDate p {
  color: #394858;
  font-size: 15.5px;
  font-weight: 600;
  margin: 0px 0px 13px 0px;
}

.selectDate span {
  color: #394858;
  font-size: 15.5px;
  font-weight: 500;
}

.eventLOcation {
  padding-block-start: 10px;
  border-bottom: 1px solid #c1c1c1;
}
.infoLabel {
  border-bottom: 1px solid #c1c1c1;
  padding: 12px 0px 20px 0px;
}
.infoLabel h2 {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.eventImage {
  padding: 18px 0px 25px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.eventImage h4 {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.eventImage p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 14px 0px;
}

.uploadLabel {
  text-align: center;
  padding: 10px 30px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.imgUpload {
  border: 1px solid #c1c1c1;
  margin-top: 15px;
  position: relative;
  width: 100%;
  max-width: 270px;
  object-fit: cover;
  padding: 30px;
}

.imgUpload img {
  width: 100%;
  max-width: 270px;
  height: 110px;
  object-fit: cover;
}

.closeIcon {
  font-size: 25px;
  position: absolute;
  right: -3px;
  top: -6px;
  color: #fff;
  background-color: #ff4300;
  font-weight: 600;
  border-radius: 5px;
}
.adBtn {
  padding: 13px 0px 20px 0px;
  border-bottom: 1px solid #c1c1c1;
}
.adBtn p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.includeResult {
  padding: 10px 0px 10px 0px;
}

.includeResult p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.addEventDate {
  position: relative;
}

.addEventDate svg {
  position: absolute;
  left: 7px;
  top: 8px;
  font-size: 24px;
  color: #000;
  font-weight: 600;
}
.timedatePicker {
  width: 220px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 40px;
}
.timedatePicker::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.activeEventSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 15px;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.activeEventSection p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}
.eventBtn {
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
  margin-top: 20px;
  border-radius: 4px;
  height: 60px;
  padding: 13px 10px;
}

.cancel button {
  /* width: 81px; */
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #fff;
}

.save button {
  width: 103px;
  height: 34px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switchButton {
  font-size: 25px;
}

.previewMembershipImgBox {
  width: 500px;
  height: 400px;
  position: relative;
  margin-top: 20px;
}
.previewImg {
  height: 400px;
  object-fit: contain;
  width: 500px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  background-color: #404040;
}
.removeImg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}

.dateSelection {
  min-width: 220px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}
.dateSelection img {
  width: 24px;
  height: 25px;
}
.dateSelection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0px 0px 10px;
}

.pushNotification h1 {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.pushNotification p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.notifyText label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
}
.notifyText input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #ffffff;

  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyText input:focus {
  border: none;
  text-decoration: none !important;
  box-shadow: none !important;
}
.notifyText input ::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.notifyButton {
  height: 54px;
  width: 301px;
  border-radius: 4px;
  background-color: #7b7a7a;
  border: 1px solid #7b7a7a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
}
.notifyButton p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4000000059604645px;
  margin: 0;
  color: #ffffff;
}
.notifyButton img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.notificationList {
  display: flex;
}
.notificationList p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin: 0;
  width: 100%;
}
.notificationList span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}
.notificationListPtag {
  font-family: Manrope;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000000;
  margin: 0px 0px 0px 5px !important;
  word-break: break-all !important;
  max-width: 330px !important;
}

.modalBody p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #212529;
}

.modalBody span {
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  color: #212529;
}
.modalHead p {
  font-size: 20px;
  font-weight: 400;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}
.modalHead span {
  font-size: 20px;
  font-weight: 700;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}
.modalBody p {
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  color: #212529;
  margin: 0;
}
.modalBody span {
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  color: #212529;
  margin: 0;
}
.btnSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.close {
  width: 90px;
  height: 34px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #000000;
}
.delete {
  width: 131px;
  height: 34px;
  border: 1px solid #ff4300;
  border-radius: 5px;
  background-color: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #ffffff;
  margin: 0;
}
.delete span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #ffffff;
  margin: 0;
}

.workoutSection {
  min-height: 52px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.workoutSection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  width: 90%;
  word-break: break-word;
  padding: 0px 10px 0px 0px;
}

.iconHolder {
  display: flex;
  justify-content: space-between;
  width: 55px;
}
.iconHolder img {
  height: 24px;
  width: 24px;
}
.btnBox {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.btnBox button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
}
.btnBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.btnBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.btnBox p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.btnBox span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.addEDit {
  margin-top: 20px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}

.workoutTYpes {
  background-color: #ececec;
  padding: 15px 20px 10px 15px;
  border-bottom: 1px solid #c1c1c1;
}

.workoutTYpes p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.workoutTYpes span {
  color: #394858;
  font-size: 16px;
  font-weight: 700;
}

.typeInput {
  background-color: #fff;
  /* padding: 0px 0px 0px 0px; */
}

.bottomBtn {
  display: flex;
  justify-content: space-between;
}

.bottomBtn button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
}
.bottomBtn button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.bottomBtn button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.bottomBtn p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bottomBtn span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

/* .noRecordFound p{
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 15px;
} */

.heading {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.47999998927116394px;
  text-align: left;
  color: #000000;
  margin: 0;
}

.noRecordFound p {
  text-align: center;
  color: #000;
  font-size: 15px;
}

.noRecordFound span {
  color: #ff4300;
  font-size: 15px;
}

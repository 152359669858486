.searchField {
  height: 38px;
  width: 183px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
}
.searchField:focus {
  box-shadow: none;
  border: 1px solid #c4c4c4;
}
.searchField ::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  font-style: Medium;
}

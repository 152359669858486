/* CustomTabButton.module.css */
.CustomTabButton {
  border-radius: 18px;
  padding: 0px 20px;
  font-family: "Manrope";
  font-size: 14.4px;
  font-weight: 500;
  margin-right: 10px;
  height: 35px;
  cursor: pointer;
}

.primaryCustom {
  background-color: #000000 !important; /* Custom primary color */
  color: white !important; /* Text color */
  border: none !important; /* Remove default border */
  height: 35px !important;
  cursor: pointer;
}

.primaryCustom:hover {
  background-color: #383737 !important; /* Custom hover color */
  cursor: pointer;
}

.secondaryCustom {
  background-color: white !important; /* Custom secondary color */
  color: black !important; /* Text color */
  border: none !important; /* Remove default border */
  height: 35px !important;
  cursor: pointer;
}

.secondaryCustom:hover {
  background-color: #dbdbdb !important; /* Custom hover color */
}

/* Add more classes for other variant colors if needed */
.imageButton {
  display: inline-flex;
  align-items: center;
  border: none !important;
  background: none !important;
  padding: 0;
  cursor: pointer;
}

.imageButton img {
  display: block;
  width: 100%;
  height: auto;
  /* border-radius: 18px;  */
  /* Adjust this value for rounded corners */
  transition: transform 0.2s; /* Smooth hover effect */
}

.imageButton img:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover */
}

.footerBtnCustom {
  background-color: #ff4300 !important; /* Custom primary color */
  border-radius: 4px;
  border: 1px solid #ff4300;
  height: 35px;
  padding: 0px 15px;
  min-width: 100px;
  max-width: 151px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerBtnCustom:disabled{
  border-color: transparent;
  opacity: 30%;
}

.footerBtnCustom:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important; /* Custom hover color */
}

.footerBtnCustom p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.footerBtnCustom span {
  font-weight: 700 !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.cancelBtn {
  background-color: #ffffff !important; /* Custom primary color */
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  height: 35px;
  padding: 0px 15px;
}

.cancelBtn:hover {
  color: #000000 !important;
  border: 1px solid #000000 !important;
  background-color: #dbdbdb !important; /* Custom hover color */
}


.activeButton {
  background-color: #ff4300 !important; /* Custom primary color */
  border-radius: 4px;
  border: 1px solid #ff4300;
  height: 35px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeButton:disabled{
  border-color: transparent;
  opacity: 30%;
}

.activeButton:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important; /* Custom hover color */
}

.activeButton p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.activeButton span {
  font-weight: 700 !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.selectForBrand {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
  width: 278px;
}
.selectForBrand label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.selectForBrand select {
  width: 278px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.selectForBrand svg {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 30px;
}

.selectInput input {
  height: 38px;
  width: 278px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .selectInput {
    width: 100%;
  }
  .selectForBrand select {
    width: 100%;
  }
  .selectForBrand svg {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 30px;
  }
  .selectInput input {
    width: 100%;
  }
}

/* 
workout select */
.selectOptionWorkout {
  min-height: 75px;
  margin-top: 20px;
  position: relative;
  width: 278px;
}
.selectOptionWorkout label p {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 400;
  margin: 0;
}

.selectOptionWorkout label span {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 700;
  margin: 0;
}
.selectOptionWorkout select {
  width: 466px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectOptionWorkout svg {
  position: absolute;
  top: 35px;
  right: -182px;
  font-size: 30px;
}

.selectTraning select {
  width: 416px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.selectTraning svg {
  position: absolute;
  top: 35px;
  right: -182px;
  font-size: 30px;
}

select {
  appearance: none;
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
  max-height: 150px;
  overflow-y: auto;
}

select::-webkit-scrollbar {
  width: 12px;
}

select::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

select::-webkit-scrollbar-track {
  background: #f1f1f1;
}

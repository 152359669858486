.heading {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.46px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.noRecordFound p {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.noRecordFound span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttonSection {
  width: 255px;
  display: flex;
  justify-content: space-between;
}
.members {
  width: 88px;
  height: 35px;
  background: #000000;
  border-radius: 18px;
  border: 1px solid #000000;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
}
.invites {
  width: 88px;
  height: 35px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
}
.search {
  width: 183px;
  height: 38px;
  margin-top: 0px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 10px;
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.search:focus {
  outline: none;
  box-shadow: none;
}
.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.personalDetail {
  display: flex;
}
.personalDetailOff {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.personalDetailOn {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.memberLimit {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin-top: 0px;
}
.detail {
  min-height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 0px 15px;
  margin-bottom: 20px;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.infoOfMember {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.name {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
  cursor: pointer;
  word-break: break-word;
}
.gender {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 300;
  line-height: 20.49px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
}
.date {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.39px;
  letter-spacing: 0.3px;
  color: #ff4300;
  margin: 0;
}
.editCross {
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editCross img {
  width: 24px;
  height: 24px;
}
.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b5b5b5;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.membersCount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.membersCount button {
  width: 134px;
  height: 34px;
  background: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.membersCount button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.membersCount button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.memberLimitHead {
  font-family: Manrope;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;
}
.optionLabel {
  margin: 20px 0px 0px 0px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.manageHead {
  margin: 10px 0px 0px 0px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  color: #394858;
}
.manageSubscription {
  margin: 20px 0px 0px 0px;
  width: 275px;
  height: 48px;
  border-radius: 4px;
  background: #ff4300;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manageSubscription p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 1.48px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}
.manageSubscription span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.48px;
  line-height: 20px;
  color: #ffffff;
}
.inviteBox {
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  padding: 20px;
}
.inviteBoxHead {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.59px;
  color: #000000;
}
.inviteInput {
  margin-top: 15px;
}

.inviteInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #555555;
}
.inviteInput input {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #f5f5f5;
}
.inviteInput::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.49px;
  color: #727272;
}
.inviteInput svg {
  vertical-align: top;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.note {
  margin-top: 15px;
}
.note p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #ff4300;
}
.note span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  color: #ff4300;
}
.inviteBtn {
  width: 280px;
  height: 41px;
  background: #ff4300;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-family: Manrope;
  font-size: 16.8px;
  font-weight: 400;
  line-height: 22.95px;
  letter-spacing: 0.48000001907348633px;
  color: #ffffff;
}
.notation {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.inviteDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.inviteDetailInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inviteDetailInfo h1 {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.34285715222358704px;
  color: #000000;
  margin: 0;
}
.inviteDetailInfo p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.2971428632736206px;
  color: #000000;
  margin: 0;
}
.inviteBtnSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  margin-top: 0px;
}
.inviteBtnSectionResend {
  width: 80px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #000000;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #000000;
  border-radius: 5px;
}
.inviteBtnSectionCancel {
  width: 80px;
  height: 34px;
  background: #ff4300;

  border: 1px solid #ff4300;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #ffffff;
  border-radius: 5px;
}
.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.headingAddMember p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}
.DetailHead {
  display: flex;
}
.DetailHead p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.59px;
  color: #000000;
  margin: 0;
}
.DetailHead h1 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.59px;
  color: #000000;
  margin: 0px 0px 0px 5px;
}
.DetailInput {
  margin: 0px 0px 15px 0px;
  position: relative;
}
.DetailInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
}
.DetailInput input {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}
.DetailInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.DetailInput select {
  font-family: Manrope;
  height: 38px;
  min-width: 310px;
  max-width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
}
.DetailInput p {
  position: absolute;
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  z-index: 999;
  margin: 0;
  top: 36px;
  left: 35px;
  cursor: pointer;
  pointer-events: none !important;
}
.phoneSelectImg {
  position: absolute;
  left: 103px;
  top: 44px;
  cursor: pointer;
  z-index: 99;
  pointer-events: none !important;
}
.selectImg {
  height: 9px;
  width: 15px;
  position: absolute;
  left: 310px;
  top: 46px;
}
.DetailInput option {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.defaultOption {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.checkBoxArea {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.checkBoxArea input {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid #828282;
  background: #f5f4f4;
}
.checkBoxArea label {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.81px;
  color: #6c757d;
}

.profileSectionHead {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 32.78px;
  color: #000000;
  margin: 0;
}
.profileSection {
  width: 100%;
  height: 190px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileSection img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  /* background-color: #404040; */
}
.profileSectionOption {
  display: flex;
  flex-direction: column;
  height: 45%;
  justify-content: space-between;
}
.profileSectionOption button {
  width: 142px;
  height: 44px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 5px;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #ffffff;
}

.profileSectionOption p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: center;
  color: #767676;
  margin: 0;
}
.timeGrid {
  width: 342px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.timeHead {
  margin-top: 15px;
  margin-bottom: 15px;
}
.timeHead span {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.timeHead p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.timeInput {
  display: flex;
  width: 237px;
  justify-content: space-between;
  align-items: center;
}
.timeInput input {
  width: 60px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  /* padding: 0px 14px; */
  text-align: center;
}
.timeInput p {
  color: #6c757d;
  margin: 0;
}
.checkboxGrp {
  display: flex;
}
.checkboxGrp .formcheckinput {
  width: 18px;
  height: 18px;
}
.personalBestTimeLabel {
  font-family: Manrope;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  color: #000000 !important;
  text-align: center;
}
.switchCase {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchCaseInfo {
  display: flex;
  flex-direction: column;
}
.switchCaseInfo h1 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  color: #000000;
}
.switchCaseInfo p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #828080;
  margin: 0;
}
.switchButton {
  font-size: 25px;
}
.cancelOrSave {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.cancelBtn {
  width: 81px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #000000;
  background-color: #ffffff;
  margin-right: 10px;
}
.saveBtn {
  width: 132px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #ffffff;
  background-color: #ff4300;
}
.saveBtn:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important; /* Custom hover color */
}

.datePickerBox {
  position: relative;
  margin-bottom: 10px;
}
.datePickerBox p {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
  margin: 0px 0px 8px 0px;
}
.datePickerBox svg {
  position: absolute;
  left: 5px;
  top: 8px;
}
.dateSelection {
  width: 350px;
  height: 38px;
  border-radius: 4px;
  background-color: #f6f6f6;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}
.dateSelection img {
  height: 25px;
  width: 24px;
}
.dateSelection p {
  opacity: 1;
  color: #6c757d !important;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 0px 10px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .option {
    width: 100%;
    display: block;
  }
  .buttonSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .search {
    width: 100%;
    height: 38px;
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0px 10px;
    font-family: Manrope;
    font-weight: 400;
    font-size: 15px;
    color: #6c757d;
  }
  .search:focus {
    outline: none;
    box-shadow: none;
  }
  .summary {
    width: 100%;
    display: block;
    justify-content: space-between;
  }
  .memberLimit {
    margin-top: 10px;
  }
  .inviteormore {
    height: auto;
  }
  .membersCount {
    flex-direction: column;
    height: 105px;
  }
  .inviteDetail {
    flex-direction: column;
    height: auto;
  }
  .inviteBtnSection {
    margin-top: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 345px) {
  .selectImg {
    height: 9px;
    width: 15px;
    position: absolute;
    left: 285px;
    top: 46px;
  }
}

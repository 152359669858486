.heading {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.46px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.noRecordFound p {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.noRecordFound span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.buttonSection {
  /* width: 255px; */
  display: flex;
  justify-content: space-between;
}

.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.personalDetail {
  display: flex;
}
.personalDetailOff {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.personalDetailOn {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.detail {
  min-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 0px 15px;
  margin-bottom: 16px;
}
.info {
  display: flex;
}
.info img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.infoOfMember {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.name {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
}
.gender {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
  word-break: break-word;
}
.date {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #ff4300;
  margin: 0;
  /* margin-left: 5px; */
}
.editCross {
  height: 58px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 5px;
}
.editCross img {
  width: 24px;
  height: 24px;
}
.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b5b5b5;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.membersCount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.membersCount button {
  width: 134px;
  height: 34px;
  background: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.membersCount button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.membersCount button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .option {
    width: 100%;
    display: block;
  }
  .buttonSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .search {
    width: 100%;
    height: 38px;
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0px 10px;
    font-family: Manrope;
    font-weight: 400;
    font-size: 15px;
    color: #6c757d;
  }
  .search:focus {
    outline: none;
    box-shadow: none;
  }
  .summary {
    width: 100%;
    display: block;
    justify-content: space-between;
  }
  .inviteormore {
    height: auto;
  }
  .membersCount {
    flex-direction: column;
    height: 105px;
  }
}
